import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export default function Footer(props) {
  return (
    <footer>
      <div class="container text-center">
        <div class="row">
          <div class="col-md-12">
            <div class="block">
              <a href="/" class="mb-4 d-inline-block">
                <StaticImage
                  src="../images/logo.svg"
                  alt="The Milestone | logo"
                  height={30}
                />
              </a>
              {/* <ul class="list-inline footer-menu">
                <li class="list-inline-item">
                  <a href="https://demo.gethugothemes.com/vex/blog">Blog</a>
                </li>
                <li class="list-inline-item">
                  <a href="https://demo.gethugothemes.com/vex/contact">
                    Contact
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://demo.gethugothemes.com/vex/terms-conditions">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://demo.gethugothemes.com/vex/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul> */}
              <p class="copyright-text">
                The Milestone est une entreprise installée à Montréal, Quebec.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
