import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header class="sticky-top bg-white">
    <div class="container">
      <nav
        class="navbar navbar-expand-lg navbar-light"
        style={{
          borderBottomColor: "gray",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
          <div>
            <a class="navbar-brand" href="#">
              <StaticImage
                height={80}
                src="../images/logo.png"
                alt="The Milestone | Logo"
              />
            </a>
          </div>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
